
















import { Component, Prop, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import PageHeader from "@/admin/components/PageHeader.vue";
import Form from "@/shared/components/form/Form.vue";
import FormBase from "@/shared/classes/form/form-base";
import { GlobalActions } from "@/shared/store/global/global.actions";
import Field, { FieldSizes } from "@/shared/classes/form/field";
import { HttpMethod } from "@/shared/helpers/requests.helper";
import { getNodeApiUrlByVersion } from "@/config";
import Course from "@/shared/modules/courses/course.model";
import { FieldTypes } from "@/shared/components/form/field-types";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";

@Component({
  components: { Form, PageHeader },
  methods: { __ },
})
export default class CoursesMaterialsDescriptionDialog extends Vue {
  @Prop() meta!: any
  form: FormBase | null = null

  created() {
    this.form = new FormBase()
      .setEntry(this.meta.item)
      .setEndpoint(`${getNodeApiUrlByVersion()}/courses/${this.meta.item.uuid}/additional-info`)
      .setAppendUuidOnSubmit(false)
      .setTranslatable(true)
      .setMethod(HttpMethod.PATCH)
      .setModel(Course)
      .setFields([
        new Field()
          .setKey('materialsDescription')
          .setType(FieldTypes.editorField)
          .setSize(FieldSizes.full)
          .isRequired()
          .setTranslatable(true),
      ])
      .setValidate(true, { onlyRegionLangRequired: true })
      .setOnSuccess(() => {
        this.$store.dispatch(GlobalActions.closeDialog)
        this.$store.dispatch(GlobalActions.showSnackBar, {
          type: SnackBarTypes.success,
          message: __('admin.components.courses.materials.dialogs.on-success'),
        })
        if (typeof this.meta.onSuccess === 'function') this.meta.onSuccess();
      })
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog)
  }
}
