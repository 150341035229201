import Model from '@/shared/classes/model'
import ICoursesCategoryResponse from "@/shared/modules/courses-categories/courses-category-response.interface";

export default class CoursesCategory extends Model<ICoursesCategoryResponse> {
  name!: string
  createdAt!: string

  transform(data: ICoursesCategoryResponse): void {
    super.transform(data)

    this.name = data.name
    this.createdAt = data.createdAt
  }
}
