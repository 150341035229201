
















import { Component, Prop, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import PageHeader from "@/admin/components/PageHeader.vue";
import Form from "@/shared/components/form/Form.vue";
import FormBase from "@/shared/classes/form/form-base";
import { GlobalActions } from "@/shared/store/global/global.actions";
import Field, { FieldSizes } from "@/shared/classes/form/field";
import { HttpMethod } from "@/shared/helpers/requests.helper";
import { getNodeApiUrlByVersion } from "@/config";
import { FieldTypes } from "@/shared/components/form/field-types";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import FormGroup from "@/shared/classes/form/group";
import CoursesMaterial from "@/shared/modules/courses-materials/courses-material.model";
import axios from "axios";

@Component({
  components: { Form, PageHeader },
  methods: { __ },
})
export default class EditCoursesMaterialsDialog extends Vue {
  static GROUP_NAME = 'name'
  static GROUP_TEXT = 'text'

  @Prop() meta!: any
  form: FormBase | null = null

  created() {
    this.form = new FormBase()
      .setEndpoint(`${getNodeApiUrlByVersion()}/course-materials`)
      .setUuid(this.meta.item.uuid)
      .setMethod(HttpMethod.PATCH)
      .setTranslatable(true)
      .setFiles(true)
      .setModel(CoursesMaterial)
      .setGroups([
        new FormGroup()
          .setKey(EditCoursesMaterialsDialog.GROUP_NAME),
        new FormGroup()
          .setKey(EditCoursesMaterialsDialog.GROUP_TEXT)
          .setTitle(__('admin.components.courses.materials.form.section-text'))
      ])
      .setFields([
        new Field()
          .setKey('name')
          .setSize(FieldSizes.full)
          .setGroup(EditCoursesMaterialsDialog.GROUP_NAME)
          .setTitle(__('admin.components.courses.materials.form.name'))
          .isRequired()
          .setTranslatable(true),
        new Field()
          .setKey('picture')
          .setType(FieldTypes.file)
          .setSize(FieldSizes.full)
          .setGroup(EditCoursesMaterialsDialog.GROUP_NAME)
          .setTranslatable(true)
          .setTitle(__('admin.components.courses.materials.form.picture'))
          .setMeta({ type: 'image', accept: 'image/*,.pdf' }),
        new Field()
          .setKey('linkToVideo')
          .setSize(FieldSizes.full)
          .setGroup(EditCoursesMaterialsDialog.GROUP_NAME)
          .setTitle(__('Link to video'))
          .setTranslatable(true)
          .setMeta({ class: 'video-link' })
          .setOnChange(async (value: any) => {
            const regex = /(http|https):\/\/vimeo\.com\/(\d+)(?:|\/\?)/;
            const targetField = this.form?.fields?.find(field => field.key === 'linkToVideo')
            if (!value) return targetField?.setAppendIcon(null)
            if (regex.test(value)) {
              try {
                await axios.get(`https://vimeo.com/api/oembed.json?url=${value}`)
                return targetField?.setAppendIcon('mdi-check-circle-outline')
              } catch (e) {}
            }

            targetField?.setAppendIcon('mdi-close-circle-outline')
          }),
        new Field()
          .setKey('text')
          .setType(FieldTypes.editorField)
          .setSize(FieldSizes.full)
          .setGroup(EditCoursesMaterialsDialog.GROUP_TEXT)
          .setTranslatable(true),
      ])
      .setValidate(true, { onlyRegionLangRequired: true })
      .setShowDefaultValidationError(true)
      .setOnSuccess(() => {
        this.$store.dispatch(GlobalActions.closeDialog)
        this.$store.dispatch(GlobalActions.showSnackBar, {
          type: SnackBarTypes.success,
          message: __('admin.components.courses.materials.edit-dialog.on-success'),
        })
        if (typeof this.meta.onSuccess === 'function') this.meta.onSuccess();
      })
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog)
  }
}
