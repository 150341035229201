import Model from '@/shared/classes/model'
import ICoursesMaterialResponse from "@/shared/modules/courses-materials/courses-material-response.interface";

export default class CoursesMaterial extends Model<ICoursesMaterialResponse> {
  courseUuid!: string
  name!: string
  text!: string
  linkToVideo!: string
  picture!: { url: string; name: string | null } | null


  transform(data: ICoursesMaterialResponse): void {
    super.transform(data)

    this.courseUuid = data.courseUuid
    this.name = data.name
    this.text = data.text
    this.linkToVideo = data.linkToVideo

    this.picture = data.picture
  }
}
