import Model from '@/shared/classes/model'
import ICoursesResponse from "@/shared/modules/courses/courses-response.interface";
import CoursesCategory from "@/shared/modules/courses-categories/courses-category.model";

export default class Course extends Model<ICoursesResponse> {
  name!: string
  courseCategoryUuid!: string
  courseCategory!: CoursesCategory
  courseCategoryName!: string
  courseCategoryCreatedAt!: string
  isVisible!: boolean
  picture!: { url: string; name: string | null } | null
  icon!: string | null
  testTitle!: string
  testDescription!: string
  testCompletionNotice!: string
  testPassingThreshold!: number
  currentUserAssignment!: {
    status: string,
    uuid: string,
    materialsPassedAt: Date | null
    lastMaterialViewedIndex: number | null
  }
  testsAmount!: number
  materialsAmount!: number
  assignments!: Record<string, any>[]
  materialsDescription!: string

  transform(data: ICoursesResponse): void {
    super.transform(data)

    this.name = data.name
    this.courseCategoryUuid = data.courseCategoryUuid
    this.courseCategoryName = data.courseCategory?.name
    this.courseCategoryCreatedAt = data.courseCategory?.createdAt
    this.isVisible = data.isVisible
    this.picture = data.picture ? { url: data.picture, name: data.pictureName } : null
    this.icon = data.icon
    this.testTitle = data.testTitle
    this.testDescription = data.testDescription
    this.testCompletionNotice = data.testCompletionNotice
    this.testPassingThreshold = data.testPassingThreshold
    this.currentUserAssignment = data.currentUserAssignment
    this.testsAmount = data.testsAmount
    this.materialsAmount = data.materialsAmount
    this.assignments = data.assignments
    this.materialsDescription = data.materialsDescription

    this.courseCategory = this.setRelation(CoursesCategory, data.courseCategory)
  }
}
