















import { Component, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import { getNodeApiUrlByVersion } from "@/config";
import { GlobalActions } from "@/shared/store/global/global.actions";
import Course from "@/shared/modules/courses/course.model";
import baseHttp from "@/shared/http";
import DataTableBase from "@/shared/classes/components/data-table/data-table";
import DataTableHeader from "@/shared/classes/components/data-table/data-table-header";
import EditAction from "@/shared/classes/components/data-table/default-actions/edit-action";
import DeleteAction from "@/shared/classes/components/data-table/default-actions/delete-action";
import AreYouSureDialog from "@/shared/components/dialogs/AreYouSureDialog.vue";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import DataTable from "@/shared/components/data-table/DataTable.vue";
import { FilterOperators } from "@/shared/classes/components/data-table/data-table-filter";
import CoursesTest from "@/shared/modules/courses-tests/courses-test.model";
import CoursesMaterialsDescriptionDialog from "@/admin/components/courses/CoursesMaterialsDescriptionDialog.vue";
import CoursesMaterialsService from "@/shared/modules/courses-materials/courses-materials.service";
import CreateCoursesMaterialsDialog from "@/admin/components/courses/CreateCoursesMaterialsDialog.vue";
import CoursesMaterial from "@/shared/modules/courses-materials/courses-material.model";
import EditCoursesMaterialsDialog from "@/admin/components/courses/EditCoursesMaterialsDialog.vue";


@Component({
  components: { DataTable },
  methods: { __ }
})
export default class Materials extends Vue {
  http = baseHttp;
  courseEntity: Course | null = null;
  table: DataTableBase | null = null

  created() {
    this.fetchCourse();

    this.table = new DataTableBase()
      .setModel(CoursesMaterial)
      .setEndpoint(`${getNodeApiUrlByVersion()}/course-materials`)
      .setDefaultFilters([
        {
          operator: FilterOperators.equals,
          key: 'courseUuid',
          value: this.$router.currentRoute.params.uuid
        }
        ])
      .setHeaders([
        new DataTableHeader()
          .setKey('name')
          .setText(__('admin.views.courses.tabs.materials.table.headers.name'))
          .setSortable(false)
      ])
      .setActions([
        new EditAction().setAction(this.openEditDialog),
        new DeleteAction()
          .setAction((item: Course) => this.$store.dispatch(GlobalActions.showDialog, {
            show: true,
            component: AreYouSureDialog,
            meta: {
              title: __('admin.views.courses.tabs.materials.table.actions.delete.title'),
              text: __('admin.views.courses.tabs.materials.table.actions.delete.text', { name: item.name }),
              onYes: () => CoursesMaterialsService.destroy(item.uuid)
                .then(() => {
                  this.tableRef.refresh()
                  this.$store.dispatch(GlobalActions.showSnackBar, {
                    type: SnackBarTypes.success,
                    message: __('admin.views.courses.tabs.materials.table.actions.delete.on-success'),
                  })
                })
            }
          }))
      ])
  }

  fetchCourse() {
    this.http.get(`${getNodeApiUrlByVersion()}/courses/${this.$router.currentRoute.params.uuid}?hasTranslations=true`)
      .then(response => this.courseEntity = response.data)
      .catch(console.warn)
  }


  openMaterialsDescriptionDialog(): void {
    if (!this.courseEntity) return;

    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CoursesMaterialsDescriptionDialog,
      maxWidth: 570,
      persistent: true,
      meta: {
        item: this.courseEntity,
        onSuccess: () => this.fetchCourse(),
      },
    })
  }

  openAddMaterialDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CreateCoursesMaterialsDialog,
      maxWidth: 970,
      persistent: true,
      meta: {
        courseUuid: this.$router.currentRoute.params.uuid,
        onSuccess: () => this.tableRef.refresh()
      }
    })
  }

  openEditDialog(item: CoursesTest): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: EditCoursesMaterialsDialog,
      maxWidth: 970,
      persistent: true,
      meta: {
        item,
        onSuccess: () => this.tableRef.refresh(),
      },
    })
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
