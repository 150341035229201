import { render, staticRenderFns } from "./Materials.vue?vue&type=template&id=b3d2c5e2&scoped=true&"
import script from "./Materials.vue?vue&type=script&lang=ts&"
export * from "./Materials.vue?vue&type=script&lang=ts&"
import style0 from "./Materials.vue?vue&type=style&index=0&id=b3d2c5e2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3d2c5e2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContent } from 'vuetify/lib/components/VContent';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VContent,VIcon})
